// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import handorgel from "handorgel";
import "mdn-polyfills/NodeList.prototype.forEach";

const HANDORGELS = document.querySelectorAll(".handorgel");

HANDORGELS.forEach((element) => {
    new handorgel(element);
});
