import jQuery from "jquery";

import "owl.carousel";

function svgjs() {
    jQuery("img.svg").each(function () {
        const $img = jQuery(this);
        const imgID = $img.attr("id");
        const imgClass = $img.attr("class");
        const imgURL = $img.attr("src");

        jQuery.get(imgURL, function (data) {
            // Get the SVG tag, ignore the rest
            let $svg = jQuery(data).find("svg");

            // Add replaced image's ID to the new SVG
            if (typeof imgID !== "undefined") {
                $svg = $svg.attr("id", imgID);
            }
            // Add replaced image's classes to the new SVG
            if (typeof imgClass !== "undefined") {
                $svg = $svg.attr("class", `${imgClass} replaced-svg`);
            }

            // Remove any invalid XML tags as per http://validator.w3.org
            $svg = $svg.removeAttr("xmlns:a");

            // Check if the viewport is set, else we gonna set it if we can.
            if (!$svg.attr("viewBox") && $svg.attr("height") && $svg.attr("width")) {
                $svg.attr("viewBox", `0 0 ${$svg.attr("height")} ${$svg.attr("width")}`);
            }

            // Replace image with new SVG
            $img.replaceWith($svg);

        }, "xml");
    });
}

jQuery(document).ready(function () {
    svgjs();

    if (!("ontouchstart" in document.documentElement)) {
        document.documentElement.className += " no-touch";
    } else {
        document.documentElement.className += " touch";
    }

    jQuery(".banner-slider").owlCarousel({
        loop: true,
        items: 1,
        margin: 0,
        nav: true,
        dots: true,
        smartSpeed: 1000,
        autoplay: true,
        autoplayTimeout: 8000
    });

    jQuery(".toggle-menu").click(function () {
        jQuery(this).toggleClass("active");
        jQuery("body").toggleClass("menu-open");
        jQuery(".toggle-search").removeClass("active");
        jQuery("body").removeClass("search-open");
    });

    jQuery(".close-sign").click(function () {
        jQuery(".toggle-menu").removeClass("active");
        jQuery("body").removeClass("menu-open");
    });

    jQuery(".toggle-search").click(function () {
        jQuery(this).toggleClass("active");
        jQuery("body").toggleClass("search-open");
        jQuery(".toggle-menu").removeClass("active");
        jQuery("body").removeClass("menu-open");
    });

    jQuery(".touch .main-navigation .menu-item-has-children > a").on("click touchstart", function (event) {
        if (!jQuery(this).parent().hasClass("open") && jQuery(this).parent().has("ul").length > 0) {
            jQuery(this).parent().find("ul").removeClass("open");
            event.preventDefault();
            event.stopPropagation();
            jQuery(this).parent().addClass("open").siblings().removeClass("open");
        }
    });
});
